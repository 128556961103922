var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pagePadding"},[_c('span',{staticClass:"titleTxt"},[_vm._v("仓库名称："+_vm._s(_vm.detailsInfo.warehouse_name))]),_c('div',{staticClass:"pageTop"},[_c('span',{staticClass:"titleTxt"},[_vm._v(_vm._s(_vm.order_type == 2 ? '报损' : '报溢')+"清单")]),_c('Table',{attrs:{"columns":_vm.listColumns,"data":_vm.meterList,"border":"","no-data-text":"暂无产品"}}),(_vm.meterList.length == 0)?_c('div',{staticClass:"summary picking"},[_vm._v("暂未报溢清单")]):_vm._e()],1),_c('div',{staticClass:"pageTop"},[_c('div',{staticClass:"inputFlex"},[_c('span',{staticClass:"titleTxt"},[_vm._v("待入库清单")]),_vm._v(" "),_c('span',{staticClass:"fr pageBtn finger btnSure marginLeft20",on:{"click":function($event){_vm.setupStatus = true}}},[_vm._v("设置")])]),_c('TableColumns',{attrs:{"productList":_vm.stayListColumns,"productData":_vm.meterList2,"option_page":_vm.option_page,"border":"","no-data-text":"暂无产品"},scopedSlots:_vm._u([{key:"supplier_id",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"inputFlex"},[_c('Select',{staticClass:"iviewIptWidth250",staticStyle:{"text-align":"left"},attrs:{"placeholder":"请选择","disabled":"","clearable":"","filterable":""},on:{"on-change":function($event){return _vm.changeIpt($event,'supplier_id',index)}},model:{value:(row.supplier_id),callback:function ($$v) {_vm.$set(row, "supplier_id", $$v)},expression:"row.supplier_id"}},_vm._l((row.supplier_list),function(item,index){return _c('Option',{key:index,attrs:{"value":item.supplier_id}},[_vm._v(_vm._s(item.supplier_name))])}),1)],1)]}},{key:"customer_name",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"inputFlex"},[_c('Select',{staticClass:"iviewIptWidth250",staticStyle:{"text-align":"left"},attrs:{"placeholder":"请选择","disabled":"","clearable":"","filterable":""},on:{"on-change":function($event){return _vm.changeIpt($event,'customerIndex',index)}},model:{value:(row.customerIndex),callback:function ($$v) {_vm.$set(row, "customerIndex", $$v)},expression:"row.customerIndex"}},_vm._l((_vm.customIdList),function(item,index){return _c('Option',{key:index,attrs:{"value":index}},[_vm._v(_vm._s(item.customer_name))])}),1)],1)]}},{key:"batch_number",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"inputFlex"},[_c('Input',{attrs:{"placeholder":"请输入","value":row.batch_number},on:{"on-change":function($event){return _vm.changeIpt($event, 'batch_number', index)}}})],1)]}},{key:"serial_number",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"inputFlex"},[_c('i-input',{attrs:{"placeholder":"请输入","value":row.serial_number},on:{"on-change":function($event){return _vm.changeIpt($event, 'serial_number', index)}}})],1)]}},{key:"unit_price",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"inputFlex"},[_c('InputNumber',{attrs:{"max":999999,"min":0,"precision":2,"active-change":false},on:{"on-change":function($event){return _vm.changeIpt($event, 'unit_price', index)}},model:{value:(row.unit_price),callback:function ($$v) {_vm.$set(row, "unit_price", $$v)},expression:"row.unit_price"}}),_c('span',[_vm._v("￥")])],1)]}},{key:"sale_unit_price",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"inputFlex"},[_c('InputNumber',{attrs:{"max":999999,"min":0,"precision":2,"active-change":false},on:{"on-change":function($event){return _vm.changeIpt($event, 'sale_unit_price', index)}},model:{value:(row.sale_unit_price),callback:function ($$v) {_vm.$set(row, "sale_unit_price", $$v)},expression:"row.sale_unit_price"}}),_c('span',[_vm._v("￥")])],1)]}},{key:"quantity",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"inputFlex"},[_c('InputNumber',{attrs:{"max":999999,"min":0,"precision":0,"active-change":false},on:{"on-change":function($event){return _vm.changeIpt($event, 'quantity', index,row.product_name,row.product_model_code,row.identification)}},model:{value:(row.quantity),callback:function ($$v) {_vm.$set(row, "quantity", $$v)},expression:"row.quantity"}})],1)]}},{key:"production_date",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"inputFlex"},[_c('Input',{staticStyle:{"width":"200px"},attrs:{"value":row.production_date,"placeholder":"请输入"},on:{"on-blur":function($event){return _vm.dataBlur($event, true)},"on-change":function($event){return _vm.changeIpt($event, 'production_date', index)}}})],1)]}},{key:"valid_period",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('div',{staticClass:"inputFlex"},[_c('Input',{staticStyle:{"width":"200px"},attrs:{"value":row.valid_period,"placeholder":"请输入"},on:{"on-blur":function($event){return _vm.dataBlur($event, true)},"on-change":function($event){return _vm.changeIpt($event, 'valid_period', index)}}})],1)]}}])}),(_vm.meterList2.length == 0)?_c('div',{staticClass:"summary picking"},[_vm._v("暂未待入库清单")]):_vm._e()],1),_c('div',{staticClass:"pageBtm clearfix"},[_c('span',{staticClass:"pageBtn finger btnSure fr marginLeft20",on:{"click":_vm.comfiy}},[_vm._v("提交")]),_c('span',{staticClass:"pageBtn finger btnReset fr",on:{"click":_vm.back}},[_vm._v("返回")])]),(_vm.setupStatus)?_c('table-setup',{attrs:{"pageList":_vm.titleList,"option_page":_vm.option_page},on:{"cancleBtn":function($event){_vm.setupStatus = false},"sureBrn":_vm.sureSetup}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }